import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import './ForwardButton.css'

export default function ForwardButton({ title, image, onClick }) {

    return <button className="forward-button" onClick={onClick}>
        <div>
            {image &&
            <span className="button-icon">
                <img src={image} width="32"/>   
            </span>
            }
            <span className="button-title">
                {title}
            </span>
            <span> <FontAwesomeIcon icon={faAngleRight} /> </span>
        </div>
    </button>
}