import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Auth0Provider } from "@auth0/auth0-react";

import { createBrowserHistory } from "history";

import { getAuthConfig } from "./config";

import App from './App';
import { versionChangeDataClear } from './util/storage'

import './index.css';

import mixpanel from 'mixpanel-browser';
mixpanel.init('3d4177cf846827accf3b5002d260c983', { debug: false, track_pageview: true, persistence: 'localStorage' });

versionChangeDataClear();

const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const authConfig = getAuthConfig();

const providerConfig = {
  domain: authConfig.domain,
  clientId: authConfig.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(authConfig.audience ? { audience: authConfig.audience } : null),
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
    {...providerConfig}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);

// register for offline-first behavior
//serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();