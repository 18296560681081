import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import mixpanel from 'mixpanel-browser';

import ForwardButton from '../components/ForwardButton'

import './Home.css'

import logo from '../images/logo.png'

export default function Main() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const today = async () => {
        mixpanel.track('Home-today recipe')
        navigate('/selection')
    }

    // const weekMenu = async () => {
    //     mixpanel.track('Home-week')
    //     const homeIngredients = loadHomeIngridients()

    //     var recipeIds = getWeekRecipes()
    //     if(recipeIds.length == 0) {
    //         recipeIds = await createNewWeekMenu(7)
    //         saveWeekRecipes(recipeIds)
    //     }

    //     const weekRecipes = await Promise.all( recipeIds )
    //     navigate(`/week-recipies`, { state: {recipies: weekRecipes} })
    // }

    const exploreRecipies = () => {
        mixpanel.track('Home-explore recipes')
        navigate(`/recipe-alternatives`)
    }

    const manageFavorites = () => {
         mixpanel.track('Home-manage favorite-recipes')
         navigate(`/favorite-recipies`)
    }
   
    return (
        <>
            <div className='app-logo'>
                <div>
                    <img style={{maxWidth: '200px'}} src={logo}  />
                </div>
            </div>
            <div className="home-content">
                <div style={{flexGrow: 1}}></div>
                <ForwardButton 
                    title={t('What are we eating today?')}
                    onClick={()=>today()}
                />
                <ForwardButton 
                    title={t('Recipes for inspiration')}
                    onClick={()=>exploreRecipies()}
                />
                <ForwardButton 
                    title={t('Your favorite recipes')}
                    onClick={()=>manageFavorites()}
                />
                <div style={{flexGrow: 1}}></div>
            </div>
        </>
    )
}