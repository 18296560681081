import { useTranslation } from "react-i18next"
import Form from 'react-bootstrap/Form'

import './RecipeTags.css'

const recipeTags = [
    {
      label: "recipe-tag.general",
      options: [
          { label: "pasta", value: "pasta" },
          { label: "meat", value: "meat" },
          { label: "fish", value: "fish" },
          { label: "chicken", value: "chicken" },
          { label: "seafood", value: "seafood" },

          // { label: "cheese", value: "cheese" },
          // { label: "eggs", value: "eggs" },
          // { label: "vegetables", value: "vegetables" },

          { label: "vegetarian", value: "vegetarian" },
        // { label: "vegan", value: "vegan" },
        // { label: "keto", value: "keto" },


          // { label: "beef", value: "beef" },

          // { label: "pancakes", value: "pancakes" },
          // { label: "porridge", value: "porridge" },
          
          //{ label: "pizza", value: "pizza" },

          // { label: "side dishes", value: "side dishes" },
          // { label: "bread", value: "bread" },
          // { label: "yeast sweet pastries", value: "yeast sweet pastries" },
          // { label: "biscuits", value: "biscuits" },
          // { label: "cakes", value: "cakes" },
          // { label: "sandwiches", value: "sandwiches" },
          // { label: "minced meat", value: "minced meat" },
          // { label: "stews", value: "stews" },
          // { label: "one pot", value: "one pot" },
          // { label: "appetizers", value: "appetizers" },
      ]
    },
    {
      label: "recipe-tag.unwanted",
      options: [

        { label: "gluten-free", value: "gluten-free" },
        { label: "dairy-free", value: "dairy-free" },
        // { label: "nut-free", value: "nut-free" },
        { label: "meat-free", value: "meat-free" },
      ]
    },
    // {
    //     label: "recipe-tag.occasion",
    //     options: [
    //       { label: "birthday", value: "birthday" },
    //       { label: "christmas", value: "christmas" },
    //       { label: "valentines-day", value: "valentines-day" },
    //       { label: "easter", value: "easter" },
    //     ]
    // },
    // {
    //     label: "recipe-tag.flavor",
    //     options: [
    //       { label: "spicy", value: "spicy" },
    //       { label: "sweet", value: "sweet" },
    //     ]
    // },
    
]

const TagOptions = ({label, options, selectedTags, setSelectedTags}) => {
  const { t } = useTranslation()
  return ( 
    <div className="recipe-tags-section" key={label}>
      <div className="recipe-tags-section-label">{t(label)}</div>
      <div className="recipe-tags-section-options">
        {options.map(tag => 
          <Form.Check
            type="switch"
            id={`recipe-tags-${tag.value}`}
            key={`recipe-tags-${tag.value}`}
            label={tag.label}
            value={tag.value}
            checked={selectedTags.includes(tag.value)}
            onChange={(tag) => {
                if (selectedTags.includes(tag.target.value)) {
                  const newTags = selectedTags.filter(item => item !== tag.target.value)
                  setSelectedTags(newTags)
                } else {
                  const newTags = [...selectedTags, tag.target.value]
                  setSelectedTags(newTags)
                }
            }}
          />
        )}
      </div>
    </div>
  )
}

const RecipeTags = ({tags, setTags}) => {
	return <div className="RecipeTags">
    { recipeTags.map(tag => 
      <TagOptions label={tag.label} options={tag.options} selectedTags={tags} setSelectedTags={setTags} key={tag.label}/>
    )}
	</div>
}

export default RecipeTags
