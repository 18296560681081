import { APP_VERSION } from "../config"

const ALL_INGRIDIENT_FELT_NAME = 'allIngredients'
const INGRIDIENT_FELT_NAME = 'homeIngredients'
const FOOD_CATEGORIES_FELT_NAME = 'foodCategories'
const COOKING_TIME_FILTER_NAME = 'cookingTimeFilter'
const MY_CHECKBOX_FELT_NAME = 'doNotAsk'
const SHOPPING_LIST_KEY = 'shoppingList'
const RECENT_RECIPES_NAME = 'recentRecipes'
const SUGGESTED_RECIPES = 'suggestedRecipes'
const WEEK_RECIPES = 'WeekRecipes'
const USER_FAVORITE_RECIPES = 'UserFavoriteRecipes'
const APP_VERSION_KEY_NAME = 'app-version'

//const DEFAULT_FOOD_CATEGORIES = ['breakfast', 'main_course', 'soup', 'dessert']
const DEFAULT_FOOD_CATEGORIES = ['breakfast', 'main_course', 'soup']
const DEFAULT_TIME_FILTER_NAME = ['quick', 'medium', 'long']

function isUserFavoriteRecipe(recipe) {
    const allFavorites = loadALlFavoriteRecipes()
    return allFavorites.map(recipe => recipe.id).includes(recipe.id)
}

function saveUserFavoriteRecipe (recipe) {
    var existingArray = JSON.parse(localStorage.getItem(USER_FAVORITE_RECIPES)) || []
    existingArray.push(recipe)
    const uniqueArray = Array.from(new Set(existingArray.map(obj => obj.id))).map(id => {
      return existingArray.find(obj => obj.id === id);
    })
    localStorage.setItem(USER_FAVORITE_RECIPES, JSON.stringify(uniqueArray))
}

function removeUserFavoriteRecipe (recipe) {
    var existingArray = JSON.parse(localStorage.getItem(USER_FAVORITE_RECIPES)) || [];
    const indexToRemove = existingArray.findIndex(obj => obj.id === recipe.id);

    if (indexToRemove !== -1) {
        existingArray.splice(indexToRemove, 1);
        localStorage.setItem(USER_FAVORITE_RECIPES, JSON.stringify(existingArray));
    }
}

function loadALlFavoriteRecipes() {
    var json = localStorage.getItem(USER_FAVORITE_RECIPES)
    const storedValues = JSON.parse(json)
    if (!storedValues) {
        return []
    }
    if (!Array.isArray(storedValues)) {
        return []
    }
    return storedValues
}

function loadAllIngridients () {
    var json = localStorage.getItem(ALL_INGRIDIENT_FELT_NAME)
    const storedValues = JSON.parse(json)
    if (!storedValues) {
        return []
    }
    if (!Array.isArray(storedValues)) {
        return []
    }
    return storedValues
}

function saveAllIngridients (ingridients) {
    localStorage.setItem(ALL_INGRIDIENT_FELT_NAME, JSON.stringify(ingridients))
}

function loadHomeIngridients () {
    var json = localStorage.getItem(INGRIDIENT_FELT_NAME)
    const storedValues = JSON.parse(json)
    if (!storedValues) {
        return []
    }
    if (!Array.isArray(storedValues)) {
        return []
    }
    return storedValues
}

function saveHomeIngridients (ingridients) {
    localStorage.setItem(INGRIDIENT_FELT_NAME, JSON.stringify(ingridients))
}

function loadFoodCategories () {
    var json = localStorage.getItem(FOOD_CATEGORIES_FELT_NAME)
    const storedValues = JSON.parse(json)
    if (!storedValues) {
        return DEFAULT_FOOD_CATEGORIES
    }
    if (!Array.isArray(storedValues)) {
        return DEFAULT_FOOD_CATEGORIES
    }
    return storedValues
}

function saveFoodCategories (categories) {
    localStorage.setItem(FOOD_CATEGORIES_FELT_NAME, JSON.stringify(categories))
}

function loadCookingTimeFilter () {
    var json = localStorage.getItem(COOKING_TIME_FILTER_NAME)
    const storedValues = JSON.parse(json)
    if (!storedValues) {
        return DEFAULT_TIME_FILTER_NAME
    }
    if (!Array.isArray(storedValues)) {
        return DEFAULT_TIME_FILTER_NAME
    }
    return storedValues
}

function saveCookingTimeFilter(timeFilter) {
    localStorage.setItem(COOKING_TIME_FILTER_NAME, JSON.stringify(timeFilter))
}

function saveDoNotAskBox (state) {
    localStorage.setItem(MY_CHECKBOX_FELT_NAME, state)
}

function loadDoNotAskBox () {
    const state = localStorage.getItem(MY_CHECKBOX_FELT_NAME)
    return state === 'true'
}

function getShoppingList() {
    const json = localStorage.getItem(SHOPPING_LIST_KEY)
    const storedValues = JSON.parse(json)
    if (!storedValues) {
        return []
    }
    return storedValues;
}

function saveShoppingList(shoppingList) {
    localStorage.setItem(SHOPPING_LIST_KEY, JSON.stringify(shoppingList))
}


function saveRecentRecipes(recipes) {
    localStorage.setItem(RECENT_RECIPES_NAME, JSON.stringify(recipes))
}

function getRecentRecipes() {
    const json = localStorage.getItem(RECENT_RECIPES_NAME)
    const storedValues = JSON.parse(json)
    if (!storedValues) {
        return []
    }
    return storedValues;
}

function getWeekRecipes() {
    const json = localStorage.getItem(WEEK_RECIPES)
    const storedValues = JSON.parse(json)
    if (!storedValues || storedValues.length <= 1) {
        return []
    }
    return storedValues;
}

function saveWeekRecipes (recipeIds) {
    localStorage.setItem(WEEK_RECIPES, JSON.stringify(recipeIds));
}

function loadSuggestedRecipes(recipeIds) {
    var json = localStorage.getItem(SUGGESTED_RECIPES)
    const storedValues = JSON.parse(json)
    if (!storedValues) {
        return []
    }
    if (!Array.isArray(storedValues)) {
        return []
    }
    return storedValues
}

function saveSuggestedRecipes(recipeIds) {
    localStorage.setItem(SUGGESTED_RECIPES, JSON.stringify(recipeIds))
}

function versionChangeDataClear() {
    const previousAppVersion = localStorage.getItem(APP_VERSION_KEY_NAME)

    if (!previousAppVersion || previousAppVersion !== APP_VERSION) {
        localStorage.removeItem(SUGGESTED_RECIPES)
        localStorage.removeItem(ALL_INGRIDIENT_FELT_NAME)
        localStorage.removeItem(RECENT_RECIPES_NAME)
        
        localStorage.removeItem('ingredients')
        localStorage.removeItem('ingredients_last_update')
    }

    localStorage.setItem(APP_VERSION_KEY_NAME, APP_VERSION)
}

export {
    DEFAULT_FOOD_CATEGORIES,
    DEFAULT_TIME_FILTER_NAME,
    loadAllIngridients,
    saveAllIngridients,
    loadHomeIngridients,
    saveHomeIngridients,
    loadFoodCategories,
    saveFoodCategories,
    saveDoNotAskBox,
    loadDoNotAskBox,
    getShoppingList,
    saveShoppingList,
    saveRecentRecipes,
    getRecentRecipes,
    saveSuggestedRecipes,
    loadSuggestedRecipes,
    saveWeekRecipes,
    getWeekRecipes,
    saveUserFavoriteRecipe,
    loadALlFavoriteRecipes,
    removeUserFavoriteRecipe,
    isUserFavoriteRecipe,
    loadCookingTimeFilter,
    saveCookingTimeFilter,
    versionChangeDataClear,
}
