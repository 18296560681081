import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"

import ForwardButton from '../components/ForwardButton'

import "./Login.css"

import logo from '../images/logo.png'

export default function Login() {
    const { t } = useTranslation()

    const {
        loginWithRedirect,
      } = useAuth0();

    return <div className="login-screen">
        <div className='login-logo'>
            <div>
                <img style={{maxWidth: '200px'}} src={logo}  />
            </div>
        </div>
        <div>{t('app.screen.login.description')}</div>
        <div className="login-action">
            <ForwardButton 
                title={t('Login')}
                onClick={()=>loginWithRedirect()}
            />
        </div>
    </div>
}