import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import RecipeTags from '../components/RecipeTags'
import { findRandomRecipesWithTags } from '../util/api'

import ForwardButton from '../components/ForwardButton'

import './SelectionFilter.css'

const StepStatus = ({currentStep, onStepClick}) => {
    return (
        <div className="status-line">
            <span className="expand"> <hr /> </span>
            <span className={`circle ${currentStep == 1 ? 'active' : 'inactive'}-circle`} onClick={() => onStepClick(1)}>1</span>
            <span className="expand"> <hr /> </span>
            <span className={`circle ${currentStep == 2 ? 'active' : 'inactive'}-circle`} onClick={() => onStepClick(2)}>2</span>
            <span className="expand"> <hr /> </span>
        </div>
    )
}

export default function SelectionFilter () {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [selectedFoodCategory, setSelectedFoodCategory] = useState('')
    const [tags, setTags] = useState([])

    const [step, setStep] = useState(1)

    const findRecipes = () => {
        const type = selectedFoodCategory
        const response = findRandomRecipesWithTags([], type, tags)
        response.then((recipes) => {
            if (recipes && recipes.length) {
                const recipeIds = recipes.map((recipe) => recipe.id).join(',')
                navigate(`/recipes-view/${recipeIds}`)
            } else {
                alert(t("Can't find any recipes, try different tags or type."))
            }
        })
    }

    const goToStep = (selectedStep) => {
        if (selectedStep > 0 && selectedStep < step) {
            if (selectedStep == 1) {
                setSelectedFoodCategory('')
            }
            setStep(selectedStep)
        }
    }

    return (
        <div className="selection-filter">
            {!selectedFoodCategory && 
                <div className='page-title'>{t('What are we eating today?')  }</div>
            }
            {selectedFoodCategory && 
                <div className="selected-food-category">{t(selectedFoodCategory)}</div>
            }

            <StepStatus currentStep={step} onStepClick={(step) => goToStep(step)} />

            { step === 1 && 
                <div className="food-filter-categories">  
                <ForwardButton 
                    image="/images/breakfast_64x64w.png"
                    title={t('breakfast')}
                    onClick={() => {
                        setSelectedFoodCategory('breakfast')
                        setStep(2)
                    }}
                />
                <ForwardButton 
                    image="/images/main_course_64x64w.png"
                    title={t('main_course')}
                    onClick={() => {
                        setSelectedFoodCategory('main_course')
                        setStep(2)
                    }}
                />
                <ForwardButton 
                    image="/images/soup_64x64w.png"
                    title={t('soup')}
                    onClick={() => {
                        setSelectedFoodCategory('soup')
                        setStep(2)
                    }}
                />
                </div>
            }

            { step === 2 &&
            <div>
                <RecipeTags tags={tags} setTags={setTags} />
            </div>
            }

            {/* false && 
            <div className="enter-food">
                <label>{t('app.screen.filter-food-selection.ingredients-to-use')}:</label>
                <div className="food-at-home">
                {homeFood.map((ingredient, index) => 
                    <HomeFoodItem ingredient={ingredient} key={`${ingredient.id}-${index}`} onClick={removeFromHomeFood} />
                )}
                </div>
                <SelectHomeFood onFilter={setFoodInputFilter} inputValue={foodInputFilter} />
                <div >
                    <SelectableFoodList 
                        foodInputFilter={foodInputFilter} 
                        selectedFood={homeFood} 
                        onFoodSelect={updateHomeFoodWithSelection} 
                        useFoodInputFilter={false}
                    />
                </div>
            </div>
            */}

            { step === 2 &&
            <div className="find-recipes">
                <ForwardButton onClick={() => findRecipes()} title={t('Select')} />
            </div>
            }
        </div>
    )
}