
const userLanguage = () => {
    return localStorage.getItem('language')
}

const setUserLanguage = (lang) => {
    localStorage.setItem('language', lang)
}

const getUserSessionLogin = () => {
    return sessionStorage.getItem('email')
}

const setUserSessionLogin = (email) => {
    sessionStorage.setItem('email', email)
}

export {
    userLanguage,
    setUserLanguage,
    getUserSessionLogin,
    setUserSessionLogin,
}
